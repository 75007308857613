
<div id="page-container">
    <app-header *ngIf="isHeaderVisible" ></app-header>
    <app-toaster></app-toaster>
    <div id="content">
        <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="isFooterVisible" ></app-footer>
    <app-loading-screen></app-loading-screen>
    <app-donation-sider id="donation-sider" *ngIf="isSiderDonationVisible"></app-donation-sider>
</div>